<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Добавление<br> карточки клиента
            <CznUserInfo></CznUserInfo>
          </h1>
          <h2>Комплексы услуг по жизненным ситуациям</h2>

          <ul class="situation-choose">
            <template v-for="situation in situationDicts">
              <li v-if="situation.situationType==='LIVE_SITUATION'"
                  :key="'zhs'+situation.id">
                <router-link :to="'/situations/situationInfo/new/'+situation.id">
                  {{situation.situationName}}
                </router-link>
              </li>
            </template>
          </ul>

          <h2>Комплексы услуг по бизнес-ситуациям</h2>

          <ul class="situation-choose">
            <template v-for="situation in situationDicts">
              <li v-if="situation.situationType==='BUSINESS_SITUATION'"
                  :key="'zhs'+situation.id">
                <router-link :to="'/situations/situationInfo/new/'+situation.id">
                  {{situation.situationName}}
                </router-link>
              </li>
            </template>
          </ul>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import CznUserInfo from "./elements/CznUserInfo";

export default {
  name: "SituationChoose",
  props: {},
  components: {CznUserInfo},
  data() {
    return {
      situationDicts: null,
      errorText: null,
      apiLoaded: false
    };
  },
  methods: {
    async loadDict() {
      let req = await this.$getApi("/situations/getSituationsDict")
      if (req.ok) {
        this.situationDicts = req.payload
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },
  },
  async beforeMount(){
    await this.loadDict()
    this.apiLoaded = true
  }
};
</script>
